<template>
  <nav v-if="authUser" class="nav sticky sticky-nav py-1 bg-dark">
    <div class="nav-item me-auto">
      <router-link to="/" class="nav-link text-white position-relative">
        <span class="logo-icon d-inline-flex align-items-center">
          <svg class="logo p-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 166.95 187.04">
            <path d="M60.63,0H0C0,.13,0,.26,0,.38,0,17.57,13.57,31.51,30.32,31.51h123L167,0Z"
            style="fill:#fff" />
            <path
              d="M77.79,39.82H17.17c0,.13,0,.25,0,.38,0,17.18,13.58,31.12,
              30.32,31.12h55.74l-33,76.31,0,0A29.76,29.76,0,0,
              0,86.1,187h0l12.67-29.28h0l51-117.94h-72Z"
              style="fill:#fff" />
          </svg>
        </span>
        <span class="logo-text fw-bold text-uppercase">Trainifier</span>
      </router-link>
    </div>

    <div v-if="authUserIsCoach && configUIMode !== 'athlete'" class="nav-item d-flex">
      <router-link :class="{ active: ['home', 'clients', 'client'].includes(currentPage) }"
        class="nav-link d-inline-flex align-items-center text-white" to="/clients">
        <fa-icon :icon="['fas', 'users']"></fa-icon>
        <span class="ms-2 d-none d-md-block">Clients</span>
        <NavBarClientList></NavBarClientList>
      </router-link>

      <router-link :class="{ active: ['programs', 'program'].includes(currentPage) }"
        class="nav-link d-inline-flex align-items-center text-white" to="/programs">
        <fa-icon :icon="['fas', 'calendar-week']"></fa-icon>
        <span class="ms-2 d-none d-md-block">Programs</span>
      </router-link>

      <router-link :class="{ active: ['library'].includes(currentPage) }"
        class="nav-link d-inline-flex align-items-center text-white" to="/library">
        <fa-icon :icon="['fas', 'book']"></fa-icon>
        <span class="ms-2 d-none d-md-block">Library</span>
      </router-link>
    </div>

    <div class="nav-item d-flex ms-auto">
      <router-link
      v-if="authUserIsCoach && (status == 'trialing' || status == 'canceled' || status == 'paused')"
      :class="{ active: ['activate'].includes(currentPage) }"
        class=" d-inline-flex align-items-center text-white text-decoration-none" to="/activate">
        <button class=" btn-sm mr-5 rounded bg-danger text-white">
          Activate now
          <Tooltip
              :content="dropdownDays" :placement="'bottom'">
              <IconButton
              :icon="'circle-info'"
              class="btn btn-circle"
            ></IconButton>
          </Tooltip>
        </button>
      </router-link>

      <router-link
      v-if="authUserIsCoach && (noPlan)"
      :class="{ active: ['activate'].includes(currentPage) }"
        class=" d-inline-flex align-items-center text-white text-decoration-none" to="/activate">
        <button class=" btn-sm mr-5 rounded bg-danger text-white">
          Subscribe now
          <Tooltip
              :content="dropdownDays" :placement="'bottom'">
              <IconButton
              :icon="'circle-info'"
              class="btn btn-circle"
            ></IconButton>
          </Tooltip>
        </button>
      </router-link>

      <a
      v-if="authUserIsCoach && (status == 'past_due')"
      :class="{ active: ['activate'].includes(currentPage) }" v-bind:href="billingUrl"
        class="nav-link d-inline-flex align-items-center text-white">
        <button class=" btn-sm mr-5 rounded bg-danger text-white"
        data-toggle="tooltip" data-placement="bottom"
        >
          Invoice due <fa-icon :icon="['fa-light', 'circle-info']"></fa-icon></button>
    </a>

      <router-link :class="{ active: ['account'].includes(currentPage) }"
        class="nav-link d-inline-flex align-items-center text-white" to="/account">
        <UserAvatar :user="authUser" :variant="'small'" class="me-2"></UserAvatar>
        {{ authUser.firstName }}
      </router-link>

      <router-link
      v-if="authUserIsCoach" :class="{ active: ['notification'].includes(currentPage) }"
        class="nav-link mt-2 align-items-center text-white"
        to="/notification">
        <span :class="notificationHasUnreadItems ? 'has-notification' : ''">
          <fa-icon :icon="['fas', 'bell']"></fa-icon>
        </span>
      </router-link>

      <!--<a @click="showDonationModal = true" class="nav-link text-white" role="button">
        <fa-icon :icon="['fas', 'mug-hot']"></fa-icon>
      </a>-->

      <a @click="showFeedbackModal = true"
      class="nav-link mt-2 align-items-center text-white" role="button">
        <fa-icon :icon="['fas', 'paper-plane']"></fa-icon>
      </a>
    </div>

    <portal to="modal">
      <transition name="modal">
        <DonationModal @close="showDonationModal = false"
        v-model="showDonationModal" v-if="showDonationModal">
        </DonationModal>
      </transition>
    </portal>

    <portal to="modal">
      <transition name="modal">
        <FeedbackModal @close="showFeedbackModal = false"
        v-model="showFeedbackModal" v-if="showFeedbackModal">
        </FeedbackModal>
      </transition>
    </portal>
  </nav>
</template>

<script>

import { mapState, mapGetters } from 'vuex';
import { httpGet } from '@/core/http';

export default {
  name: 'NavBar',
  components: {
    UserAvatar: () => import('@/components/UserAvatar'),
    DonationModal: () => import('@/components/modal/DonationModal'),
    FeedbackModal: () => import('@/components/modal/FeedbackModal'),
    NavBarClientList: () => import('@/components/bar/NavBarClientList'),
    Tooltip: () => import('@/components/Tooltip'),
    IconButton: () => import('@/components/button/IconButton'),
  },
  computed: {
    ...mapState('config', ['configUIMode']),
    ...mapState('auth', ['authUser']),
    ...mapState('client', ['clientCount']),
    ...mapGetters('auth', ['authUserIsAdmin', 'authUserIsCoach', 'authUserIsSubscriptionPlan']),
    ...mapGetters('notification', ['notificationHasUnreadItems']),
    isVIP() {
      return this.plan.coachingPlanId === 1;
    },
    capacity() {
      const { capacity } = this.plan;
      return capacity === -1 ? 'Unlimited' : capacity;
    },
    currentPage() {
      try {
        return this.$route.name.toLowerCase();
      } catch {
        return '';
      }
    },
  },
  watch: {
    clientCount(newVal) {
      if (this.plan && this.plan.clientsCount !== newVal) {
        this.plan.clientsCount = newVal;
      }
    },
    // TODO: DEPRECATED
    async fetchSubscriptionPlan() {
      if (!this.authUserIsCoach) return;
      try {
        const { data } = await httpGet('/subscription');
        this.subscriptionPlan = data;
        console.log(data);
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      }
    },
  },
  methods: {
    async fetchExpiryInfo() {
      if (!this.authUserIsCoach) return;
      try {
        this.isLoading = true;
        this.noPlan = false;
        const { data } = await httpGet('/subscription/stripeSubscriptionExpiry');
        const dayss = data[0];
        console.log('Subscription type: ');
        console.log(data);
        this.days = dayss;
        const status = data[1];
        this.status = status;
        switch (status) {
          case ('canceled'):
            this.dropdownDays = 'Please activate a subscription.';
            break;
          case ('paused'):
            this.dropdownDays = `Your subscription expires in ${dayss} days. `;
            break;
          case ('trialing'):
            this.dropdownDays = `Your trial expires in ${dayss} days`;
            break;
          default:
            this.dropdownDays = '';
            break;
        }
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
        this.noPlan = true;
        this.dropdownDays = 'Please activate a subscription.';
      } finally {
        this.isLoading = false;
      }
    },
    async fetchBillingPortal() {
      if (!this.authUserIsCoach) return;
      this.isFetchingBillingUrl = true;
      try {
        const { data: { url } } = await httpGet('/subscription/billingurl');
        // window.location.href = url;
        this.billingUrl = url;
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isFetchingBillingUrl = false;
      }
    },
  },
  data() {
    return {
      showDonationModal: false,
      showFeedbackModal: false,
      subscriptionPlan: null,
      isLoading: false,
      isFetchingBillingUrl: false,
      billingUrl: null,
      days: null,
      status: null,
      dropdownDays: null,
      noPlan: null,
    };
  },
  mounted() {
    // TODO: DEPRECATED
    this.fetchExpiryInfo();
    // this.fetchBillingPortal();
  },
};

</script>

<style lang="scss" scoped>
@import "@/scss/vars.scss";

.nav {
  z-index: 1106;
}

.logo-text {
  padding-left: 2rem;
}

.logo-icon {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  background-color: $success;
  border-radius: 3px;
}

.logo {
  width: 1.5rem;
  height: auto;

  path {
    fill: white !important;
  }
}

.has-notification {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    right: -.25rem;
    top: -.25rem;
    width: .5rem;
    height: .5rem;
    border-radius: 50%;
    background-color: $danger;
  }
}

.nav-link {
  &.active {
    position: relative;

    >* {
      position: relative;
      z-index: 1;
    }

    &::before {
      content: "";
      position: absolute;
      pointer-events: none;
      background: rgba(255, 255, 255, .125);
      left: 0;
      right: 0;
      top: -.25rem;
      bottom: -.25rem;
    }
  }
}
</style>
